import { PortableText } from '@portabletext/react'
import { graphql, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import MainLayout from '../layouts/MainLayout'

const PostTemplate: React.FC<PageProps<any>> = (props) => (
  <MainLayout invert>
    <Seo
      seo={{
        title: props.data.post?.seoTitle,
        description: props.data.post?.seoDescription,
      }}
    />
    <div className="fixed top-0 bottom-0 left-0 right-0">
      <StaticImage
        className="-hue-rotate-60"
        src="../assets/post-bg.jpg"
        role="presentation"
        alt=""
        layout="fullWidth"
        objectFit="cover"
        objectPosition="top"
      />
    </div>
    <main className="relative z-20 ">
      <div className="max-w-screen-xl p-4 m-auto md:p-8">
        <h1 className="block text-4xl font-black text-white uppercase md:text-4xl xl:text-8xl">
          <div className="p-8">{props.data.post?.title}</div>
        </h1>
        <div className="max-w-screen-xl p-4 prose prose-xl md:p-8 bg-neutral-100 prose-neutral">
          <PortableText value={props.data.post?._rawBody} />
        </div>
      </div>
    </main>
  </MainLayout>
)

export const query = graphql`
  query GetPost($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      title
      id
      seoTitle
      seoDescription
      _rawBody
    }
  }
`

export default PostTemplate
